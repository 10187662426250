import CartActionTypes from './cart.types';

export const addToCart = (item) => ({
    type: CartActionTypes.ADD_ITEM,
    payload: item
})
export const removeItemFromCart = (item) => ({
    type: CartActionTypes.REMOVE_ITEM,
    payload: item
})
export const removeAllItemFromCart = () => ({
    type: CartActionTypes.REMOVE_ALL_ITEM,
})
export const ToggleCart = () => ({
    type: CartActionTypes.HIDE_CART,
})
export const ToggleEmptyCartPop = (value) => ({
    type: CartActionTypes.EMPTY_CART_POP,
    payload: value
})
export const setUrlData = (value) => ({
    type: CartActionTypes.SET_URL_DATA,
    payload: value
})
export const ToggleExitPopUp = () => ({
    type: CartActionTypes.EXIT_POP_UP,
})
export const actionAddonCheck = () => ({
    type: CartActionTypes.ADD_ON_CHECK,
})
export const setPageName = (page) => ({
    type: CartActionTypes.SET_PAGE,
    payload: page
})
export const setTrainNo = (trainNo) => ({
    type: CartActionTypes.SET_TRAIN_NO,
    payload: trainNo
})
export const setBoardingStation = (station) => ({
    type: CartActionTypes.SET_BOARDING_STATION,
    payload: station
})
export const setBoardingDate = (date) => ({
    type: CartActionTypes.SET_BOARDING_DATE,
    payload: date
})
export const setExpireDate = (date) => ({
    type: CartActionTypes.SET_EXPIRE_DATE,
    payload: date
})
export const setPnr = (pnr) => ({
    type: CartActionTypes.SET_PNR,
    payload: pnr
})
export const setStationsDetailStart= () => ({
    type: CartActionTypes.FETCH_STATIONS_START
})
export const setStationsDetailSuccess = (station) => ({
    type: CartActionTypes.FETCH_STATIONS_SUCCESS,
    payload: station
})
export const setStationsDetailFailure= (error) => ({
    type: CartActionTypes.FETCH_STATIONS_FAILURE,
    payload:error
})
export const setPnrDetailStart= () => ({
    type: CartActionTypes.FETCH_PNR_START
})
export const setPnrDetailSuccess = (data) => ({
    type: CartActionTypes.FETCH_PNR_SUCCESS,
    payload: data
})
export const setPnrDetailFailure= (error) => ({
    type: CartActionTypes.FETCH_PNR_FAILURE,

})
export const setBulkOrderStart= () => ({
    type: CartActionTypes.BULK_ORDER_START
})
export const setBulkOrderSuccess = (data) => ({
    type: CartActionTypes.BULK_ORDER_SUCCESS,
    payload: data
})
export const setBulkOrderFailure= (error) => ({
    type: CartActionTypes.BULK_ORDER_FAILURE,

})
export const setStationsDetailAllStart= () => ({
    type: CartActionTypes.FETCH_ALL_STATIONS_START
})
export const setStationsDetailAllSuccess = (station) => ({
    type: CartActionTypes.FETCH_ALL_STATIONS_SUCCESS,
    payload: station
})
export const setStationsDetailAllFailure= (error) => ({
    type: CartActionTypes.FETCH_ALL_STATIONS_FAILURE,
    payload:error
})
export const setStation= (station) => ({
    type: CartActionTypes.SET_SELECTED_STATION,
    payload: station    
})
export const setPnrData= (data) => ({
    type: CartActionTypes.SET_PNR_DATA,
    payload: data    
})
export const setAddMoreItem= (item) => ({
    type: CartActionTypes.SET_ADD_MORE_ITEM,
    payload: item    
})
export const setAddMoreItemFailure= (errorMessage) => ({
    type: CartActionTypes.SET_ADD_MORE_ITEM_FAILURE,
    payload: errorMessage
})
export const ToggleStationList = () => ({
    type: CartActionTypes.HIDE_STATION_LIST,
})
export const toggleAddMoreItems = () => ({
    type: CartActionTypes.HIDE_ADD_MORE_ITEMS,
})
export const setPriceStart= () => ({
    type: CartActionTypes.FETCH_PRICE_START
})
export const setPriceSuccess = (price) => ({
    type: CartActionTypes.FETCH_PRICE_SUCCESS,
    payload: price
})
export const setPriceFailure= (error) => ({
    type: CartActionTypes.FETCH_PRICE_FAILURE,
    payload:error
})
export const setCouponCode= code => ({
    type: CartActionTypes.SET_COUPON_CODE,
    payload:code
})
export const setCouponData= data => ({
    type: CartActionTypes.SET_COUPON_DATA,
    payload:data
})
export const setCouponApplyStart= () => ({
    type: CartActionTypes.APPLY_COUPON_CODE_START
})
export const setCouponApplySuccess = (coupondata) => ({
    type: CartActionTypes.APPLY_COUPON_CODE_SUCCESS,
    payload: coupondata
})
export const setCouponApplyFailure= (error) => ({
    type: CartActionTypes.APPLY_COUPON_CODE_FAILURE,
    payload:error
})
export const setPhoneNo= data => ({
    type: CartActionTypes.SET_PHONE_NO,
    payload:data
})
export const setName= data => ({
    type: CartActionTypes.SET_NAME,
    payload:data
})
export const setSeatNumber= data => ({
    type: CartActionTypes.SET_SEAT_NUMBER,
    payload:data
})
export const setCoachNumber= data => ({
    type: CartActionTypes.SET_COACH_NUMBER,
    payload:data
})
export const setEmailId= data => ({
    type: CartActionTypes.SET_EMAIL_ID,
    payload:data
})
export const setComment= data => ({
    type: CartActionTypes.SET_COMMENT,
    payload:data
})
export const setStations= data => ({
    type: CartActionTypes.SET_STATIONS,
    payload:data
})
export const checkLeadTimeStart= () => ({
    type: CartActionTypes.LEAD_TIME_CHECK_START
})
export const checkLeadTimeSuccess = (data) => ({
    type: CartActionTypes.LEAD_TIME_CHECK_SUCCESS,
    payload: data
})
export const checkLeadTimeFailure= (error) => ({
    type: CartActionTypes.LEAD_TIME_CHECK_FAILURE,
    payload:error
})
export const setInCompleteStart= () => ({
    type: CartActionTypes.INCOMPLETE_ORDER_START
})
export const setInCompleteIsFetching = (data) => ({
    type: CartActionTypes.SET_INCOMPLETE_IS_FETCHING,
    payload: data
})
export const setInCompleteSuccess = (coupondata) => ({
    type: CartActionTypes.INCOMPLETE_ORDER_SUCCESS,
    payload: coupondata
})
export const setInCompleteFailure= (error) => ({
    type: CartActionTypes.INCOMPLETE_ORDER_FAILURE,
    payload:error
})
export const setZeroContact= (data) => ({
    type: CartActionTypes.SET_ZERO_CONTACT,
    payload:data
})
export const setStationFetching = (data) => ({
    type: CartActionTypes.SET_STATION_IS_FETCHING,
    payload: data
})
export const setProductSample = (data) =>({
    type:CartActionTypes.SET_PRODUCT_SAMPLES,
    payload:data
})
export const setSampleSelected = (data) =>({
    type:CartActionTypes.SET_SAMPLE_SELECTED,
    payload:data
})
export const setVersion = (data) =>({
    type:CartActionTypes.SET_VERSION,
    payload:data
})
export const setHomeTab = (data) =>({
    type:CartActionTypes.SET_HOME_TAB,
    payload:data
})