const cartActionTypes = {
    ADD_ITEM:'ADD_ITEM',
    UPDATE_CART_STATUS:'UPDATE_CART_STATUS',
    REMOVE_ITEM:'REMOVE_ITEM',
    REMOVE_ALL_ITEM:'REMOVE_ALL_ITEM',
    HIDE_CART:'HIDE_CART',
    ADD_ON_CHECK: 'ADD_ON_CHECK',
    SET_PAGE:'SET_PAGE',
    SET_TRAIN_NO:'SET_TRAIN_NO',
    SET_BOARDING_DATE:'SET_BOARDING_DATE',
    SET_EXPIRE_DATE:'SET_EXPIRE_DATE',
    SET_BOARDING_STATION:'SET_BOARDING_STATION',
    SET_PNR:'SET_PNR',
    SET_STATION_IS_FETCHING:'SET_STATION_IS_FETCHING',
    FETCH_STATIONS_START: 'FETCH_STATIONS_START',
    FETCH_STATIONS_SUCCESS: 'FETCH_STATIONS_SUCCESS',
    FETCH_STATIONS_FAILURE: 'FETCH_STATIONS_FAILURE',
    SET_ALL_STATION_IS_FETCHING:'SET_ALL_STATION_IS_FETCHING',
    FETCH_ALL_STATIONS_START: 'FETCH_ALL_STATIONS_START',
    FETCH_ALL_STATIONS_SUCCESS: 'FETCH_ALL_STATIONS_SUCCESS',
    FETCH_ALL_STATIONS_FAILURE: 'FETCH_ALL_STATIONS_FAILURE',
    SET_STATIONS:'SET_STATIONS',
    SET_SELECTED_STATION: 'SET_SELECTED_STATION',
    SET_ADD_MORE_ITEM: 'SET_ADD_MORE_ITEM',
    SET_ADD_MORE_ITEM_FAILURE: 'SET_ADD_MORE_ITEM_FAILURE',
    HIDE_STATION_LIST: 'HIDE_STATION_LIST',
    HIDE_ADD_MORE_ITEMS: 'HIDE_ADD_MORE_ITEMS',
    FETCH_PRICE_START: 'FETCH_PRICE_START',
    FETCH_PRICE_SUCCESS: 'FETCH_PRICE_SUCCESS',
    FETCH_PRICE_FAILURE: 'FETCH_PRICE_FAILURE',
    SET_COUPON_CODE:'SET_COUPON_CODE',
    SET_COUPON_DATA:'SET_COUPON_DATA',
    APPLY_COUPON_CODE_START: 'APPLY_COUPON_CODE_START',
    APPLY_COUPON_CODE_SUCCESS: 'APPLY_COUPON_CODE_SUCCESS',
    APPLY_COUPON_CODE_FAILURE: 'APPLY_COUPON_CODE_FAILURE',
    SET_PHONE_NO:'SET_PHONE_NO',
    SET_NAME:'SET_NAME',
    SET_SEAT_NUMBER:'SET_SEAT_NUMBER',
    SET_COACH_NUMBER:'SET_COACH_NUMBER',
    SET_EMAIL_ID:'SET_EMAIL_ID',
    SET_COMMENT:'SET_COMMENT',
    LEAD_TIME_CHECK_START: 'LEAD_TIME_CHECK_START',
    LEAD_TIME_CHECK_SUCCESS: 'LEAD_TIME_CHECK_SUCCESS',
    LEAD_TIME_CHECK_FAILURE: 'LEAD_TIME_CHECK_FAILURE',
    INCOMPLETE_ORDER_START: 'INCOMPLETE_ORDER_START',
    SET_INCOMPLETE_IS_FETCHING:'SET_INCOMPLETE_IS_FETCHING',
    INCOMPLETE_ORDER_SUCCESS: 'INCOMPLETE_ORDER_SUCCESS',
    INCOMPLETE_ORDER_FAILURE: 'INCOMPLETE_ORDER_FAILURE',
    SET_ZERO_CONTACT:'SET_ZERO_CONTACT',
    SET_PRODUCT_SAMPLES:'SET_PRODUCT_SAMPLES',
    SET_SAMPLE_SELECTED: 'SET_SAMPLE_SELECTED',
    SET_VERSION:'SET_VERSION',
    SET_HOME_TAB: 'SET_HOME_TAB',
    FETCH_PNR_START: 'FETCH_PNR_START',
    FETCH_PNR_SUCCESS: 'FETCH_PNR_SUCCESS',
    FETCH_PNR_FAILURE: 'FETCH_PNR_FAILURE',
    SET_PNR_DATA:'SET_PNR_DATA',
    EMPTY_CART_POP:'EMPTY_CART_POP',
    EXIT_POP_UP:'EXIT_POP_UP',
    BULK_ORDER_START:'BULK_ORDER_START',
    BULK_ORDER_SUCCESS:'BULK_ORDER_SUCCESS',
    BULK_ORDER_FAILURE:'BULK_ORDER_FAILURE',
SET_URL_DATA: 'SET_URL_DATA'
}

export default cartActionTypes;