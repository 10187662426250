import { takeLatest, put, call, all } from 'redux-saga/effects';

import { smallBannerSelectionSuccess, smallBannerSelectionFailure } from './small-banner.action';
import smallBannerTypes from './small-banner.types';

export function* fetchSmallBannerAsync() {
    try {
        const res = yield call(fetch,'https://foodapi.railyatri.in/live/mapiV2/BannerData/src/1/type/1')
        const smallBanner = yield res.json();
        if(smallBanner.success !== false) {
            yield put(smallBannerSelectionSuccess(smallBanner));
        } else {
            yield put(smallBannerSelectionSuccess(null));
        }

    } catch (error) {
        yield put(smallBannerSelectionFailure(error));
    }
}

export function* fetchSmallBannerStart() {
    yield takeLatest(smallBannerTypes.FETCH_SMALLBANNER_START, fetchSmallBannerAsync)
}

export function* smallBannerSaga() {
    yield all([
        call(fetchSmallBannerStart)
    ])
}