import MainBannerTypes from './main-banner.types';

export const mainBannerSelectionStart = () =>({
    type: MainBannerTypes.FETCH_MAINBANNER_START,   
})
export const mainBannerSelectionSuccess = mainBanner =>({
    type:MainBannerTypes.FETCH_MAINBANNER_SUCCESS,
    payload:mainBanner
})

export const mainBannerSelectionFailure = errorMessage =>({
    type: MainBannerTypes.FETCH_MAINBANNER_FAILURE,
    payload:errorMessage
})