import { takeLatest, all, call, put, select, debounce } from 'redux-saga/effects';
import Cookie from 'js-cookie';

import CartActionTypes from '../cart/cart.types';
import {
    selectCartItemsCount,
    selectToggle,
    selectTrainNo,
    selectBoardingStation,
    selectBoardingDate,
    selectPnr,
    selectQmid,
    selectStation,
    selectCartItems,
    selectPageName,
    selectGetItemXQty,
    selectCouponCode,
    selectCartTotal,
    selectPrice,
    selectCouponData,
    selectName,
    selectSeatNumber,
    selectCoachNumber,
    selectEmail,
    selectPhoneNo,
    selectComments,
    selectcartData,
    selectStations,
    selectAddMoreItemToggle,
    selectStationToggle,
    selectZeroContact,
    selectSamples,
    selectVersion,
    selectHomeTab,
    selectUrlData,
} from '../cart/cart.selector';
import {
    setStationsDetailFailure,
    setStationsDetailSuccess,
    setStationsDetailAllSuccess,
    setStationsDetailAllFailure,
    setStation,
    setAddMoreItem,
    setAddMoreItemFailure,
    setPriceFailure,
    setPriceSuccess,
    setCouponApplyFailure,
    setCouponApplySuccess,
    setInCompleteSuccess,
    setInCompleteFailure,
    checkLeadTimeFailure,
    checkLeadTimeSuccess,
    setInCompleteStart,
    toggleAddMoreItems,
    setCouponData,
    ToggleStationList,
    setInCompleteIsFetching,
    setStationFetching,
    setProductSample,
    setSampleSelected,
    setVersion,
    setPnrData,
    setBoardingStation,
    setBoardingDate,
    setTrainNo,
    setCoachNumber,
    setSeatNumber,
    removeAllItemFromCart,
    ToggleEmptyCartPop,
    setBulkOrderSuccess,
    setBulkOrderFailure
} from '../cart/cart.action';
import { menuCategorySelectionSuccess } from '../menu-category/menu-category.action';
import { fetchMenuItemSuccess } from '../menu-item/menuItem.action';
import { updateCartItemStatus } from './cart.utills';
import cartActionTypes from '../cart/cart.types';

export function* onBulkOrderGet() {
    try {
        const PHPSESSID = yield Cookie.get('PHPSESSID');
        const trainno = yield select(selectTrainNo);
        const broadingStation = yield select(selectBoardingStation);
        const boardingDate = yield select(selectBoardingDate);
        const pnr = yield select(selectPnr);
        const name = yield select(selectName);
        const seatNo = yield select(selectSeatNumber);
        const coachNo = yield select(selectCoachNumber);
        const email = yield select(selectEmail);
        const comments = yield select(selectComments);
        const phoneNo = yield select(selectPhoneNo);
        const station = yield select(selectStation);
        const cartData = yield select(selectcartData);
        const temp1 = yield select(selectStations);
        const coupondata = yield select(selectCouponData);
        var zeroContact = yield select(selectZeroContact);
        const samples = yield select(selectSamples)
        if (zeroContact === true) {
            zeroContact = " Zero Contact";
        } else {
            zeroContact = '';
        }

        const userDetails = yield ({
            seat_no: seatNo, coach_no: coachNo, name: name, phoneno: phoneNo, phoneno1: "",
            email: email + "", comments: comments + "" + zeroContact + "", samples: samples.join(',')
        });

        const restDetails = yield ({
            delivery_date: station.delivery_date, train_start_day: station.train_start_day,
            dstn: station.dstn, station_id: station.station_id, name: station.name, ETA: station.ETA
        });

        var formData = new FormData();
        formData.append("PHPSESSID", PHPSESSID);
        formData.append("trainno", trainno);
        formData.append("boardingstation", broadingStation);
        formData.append("boardingdate", boardingDate);
        formData.append("pnrno", pnr);
        formData.append("rest", station.res_id);
        formData.append("userDetails", JSON.stringify(userDetails));
        formData.append("restDetails", JSON.stringify(restDetails));
        formData.append("src", 5);
        if (coupondata.discount !== undefined) {
            formData.append("discount_detail", JSON.stringify(coupondata));
        }
        formData.append("cart", JSON.stringify(cartData));
        formData.append("temp1", JSON.stringify(temp1));
        const requestOptions = {
            method: 'POST',
            body: formData
        };

        const url = 'https://foodapi.railyatri.in/live/index.php/mapiV2/BulkOrder';
        const version = yield select(selectVersion);
        const res = yield call(fetch, url, requestOptions);
        const data = yield res.json();
        if (data.status === 200) {
            yield put(setBulkOrderSuccess(data));
        } else {
            yield put(setBulkOrderFailure(data));
        }

    } catch (error) {
        yield put(setBulkOrderFailure(error));
    }
}

export function* onBulkOrder() {
    yield takeLatest(cartActionTypes.BULK_ORDER_START, onBulkOrderGet)
}

export function* onfetchPnrDataSet() {
    const pnr = yield select(selectPnr);
    yield put(setStationsDetailSuccess([]));
    yield put(setStation([]));
    yield put(setPnrData([]));
    yield put(setBoardingStation(''));
    let today = new Date(new Date().getTime());
    let dd = today.getDate();
    let mm = today.getMonth() + 1;
    let yyyy = today.getFullYear();

    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    today = dd + "-" + mm + "-" + yyyy;
    yield put(setBoardingDate(today));
    yield put(setTrainNo(''));
    if (pnr.length === 10) {
        const PHPSESSID = yield Cookie.get('PHPSESSID');
        const url = 'https://foodapi.railyatri.in/live/index.php/mapiV2/getPnrDetails/pnr/' + pnr + '/type/web/sessID/' + PHPSESSID + '/src/5';
        const res = yield call(fetch, url);
        const data = yield res.json();
        if (data.status === 200) {
            yield put(setPnrData(data));
            yield put(setBoardingStation(data.boardingstation));
            yield put(setBoardingDate(data.boardingdate));
            yield put(setTrainNo(data.trainno));
        }
    }
}
export function* onfetchPnrData() {
    yield takeLatest(CartActionTypes.FETCH_PNR_START, onfetchPnrDataSet);
}
export function* onStationSelectV3() {
    const station = yield select(selectStation);
    yield put(removeAllItemFromCart());
    let Qmids = yield Object.keys(station.active_menuName).join(',');

    if (Qmids === undefined) {
        Qmids = station.active_menuName;
    }
    const uniquecatId = [];
    station.active_menulist.map(catid => {
        if (uniquecatId.indexOf(catid.category_main) === -1 && catid.category_main != null) {
            uniquecatId.push(catid.category_main)
        }
    });
    const catId = uniquecatId.join(',');
    const resMC = yield call(fetch, 'https://foodapi.railyatri.in/live/index.php/mapiVeg/qmlistv3/qmid/' + Qmids);
    const menuCategory = yield resMC.json();
    if (menuCategory.status === undefined) {
        const res = yield call(fetch, 'https://foodapi.railyatri.in/live/mapiVeg/QMCategoryv3/cat/' + catId)
        const qmCategory = yield res.json();
        yield put(menuCategorySelectionSuccess(qmCategory))
        yield put(fetchMenuItemSuccess(menuCategory));
        yield put(ToggleStationList());
    }
}

export function* paymentGatewayPost(data) {

    const userID = yield data.ry_user_id;
    const custID = yield data.cust_id;
    const ecomType = 0;
    const comboData = 0;
    const PhoneNo = yield data.phone;
    const email = yield data.email;
    const orderAmount = yield data.online;
    const deliveryCharge = yield data.delivery_charge;
    const couponCode = yield select(selectCouponCode);
    const coupondata = yield select(selectCouponData);
    var discountedAmount = 0;
    if (coupondata.discount !== undefined) {
        discountedAmount = yield coupondata.discount;
    }

    yield Cookie.set('user_uuid', userID, { path: '/', sameSite: "Strict" });

    const food_order_details = ({
        user_id: userID, invoice_id: custID, cust_id: custID, ecomm_type: ecomType, combo_data: comboData, coupon_code: couponCode, mobile_number: PhoneNo,
        email: email, wallet_debit: 0, delivery_charge: deliveryCharge, orderAmount: orderAmount, discounted_amount: discountedAmount

    })

    yield Cookie.set('food_order_details', JSON.stringify(food_order_details), { path: '/', sameSite: "Strict" });
    const urlData = yield select(selectUrlData);
    yield window.location.href = '/pay';
}

export function* onInCompleteOrderAsync() {
    yield put((setInCompleteIsFetching(true)))
    try {
        const PHPSESSID = yield Cookie.get('PHPSESSID');
        const trainno = yield select(selectTrainNo);
        const broadingStation = yield select(selectBoardingStation);
        const boardingDate = yield select(selectBoardingDate);
        const pnr = yield select(selectPnr);
        const name = yield select(selectName);
        const seatNo = yield select(selectSeatNumber);
        const coachNo = yield select(selectCoachNumber);
        const email = yield select(selectEmail);
        const comments = yield select(selectComments);
        const phoneNo = yield select(selectPhoneNo);
        const station = yield select(selectStation);
        const cartData = yield select(selectcartData);
        const temp1 = yield select(selectStations);
        const coupondata = yield select(selectCouponData);
        var zeroContact = yield select(selectZeroContact);
        const samples = yield select(selectSamples)
        if (zeroContact === true) {
            zeroContact = " Zero Contact";
        } else {
            zeroContact = '';
        }
const urlData = yield select(selectUrlData);
        const queryString = require('query-string');
        const parsed = queryString.parse(urlData)

        const userDetails = yield ({
            seat_no: seatNo, coach_no: coachNo, name: name, phoneno: phoneNo, phoneno1: "",
            email: email + "", comments: comments + "" + zeroContact + "", samples: samples.join(',')
        });

        const restDetails = yield ({
            delivery_date: station.delivery_date, train_start_day: station.train_start_day,
            dstn: station.dstn, station_id: station.station_id, name: station.name, ETA: station.ETA
        });

        var formData = new FormData();
        formData.append("PHPSESSID", PHPSESSID);
        formData.append("trainno", trainno);
        formData.append("boardingstation", broadingStation);
        formData.append("boardingdate", boardingDate);
        formData.append("pnrno", pnr);
        formData.append("rest", station.res_id);
        formData.append("userDetails", JSON.stringify(userDetails));
        formData.append("restDetails", JSON.stringify(restDetails));
        formData.append("order_type", 25);
        formData.append("src", 5);
        if (coupondata.discount !== undefined) {
            formData.append("discount_detail", JSON.stringify(coupondata));
        }
        formData.append("cart", JSON.stringify(cartData));
formData.append("utm", parsed.utm_source);
        formData.append("temp1", JSON.stringify(temp1));

        const requestOptions = {
            method: 'POST',
            body: formData
        };

        var url = 'https://foodapi.railyatri.in/live/index.php/mapiV2/PassengerUpdateApi';
        const version = yield select(selectVersion);
        if (version === "3") {
            url = 'https://foodapi.railyatri.in/live/index.php/mapiV3/PassengerUpdateApi';
        }
        const res = yield call(fetch, url, requestOptions);
        const data = yield res.json();
        if (data.status === 200) {
            //yield put(setInCompleteSuccess(data));
            yield call(paymentGatewayPost, data)
        } else {
            yield put(setInCompleteFailure(data));
        }

    } catch (error) {
        yield put(setInCompleteFailure(error));
    }
}

export function* onIncompleteStart() {
    yield takeLatest(cartActionTypes.INCOMPLETE_ORDER_START, onInCompleteOrderAsync)
}

export function* onCheckLeadTimeAsync() {
    try {
        const station = yield select(selectStation);
        var resId = station.res_id;
        var resDetails = yield resId.split('_');
        const res_id = resDetails[0];
        const date = yield station.delivery_date;
        const time = yield station.ETA;
        const url = "https://foodapi.railyatri.in/live/index.php/mapiV2/leadTime/res_id/" + res_id + '/date/' + date + '/time/' + time;
        const res = yield call(fetch, url);
        const data = yield res.json();
        yield put(checkLeadTimeSuccess(data));
        if (data.status === 1) {
            yield put(setInCompleteStart())
        }
    } catch (error) {
        yield put(checkLeadTimeFailure(error));
    }
}

export function* onCheckLeadTimeStart() {
    yield takeLatest(cartActionTypes.LEAD_TIME_CHECK_START, onCheckLeadTimeAsync)
}


export function* onUnsetCouponData() {
    yield call(onSetPriceAsync);
}

export function* onSetCouponData() {
    yield takeLatest(cartActionTypes.SET_COUPON_DATA, onUnsetCouponData)
}

export function* onApplyCouponAsync() {

    try {
        const couponCode = yield select(selectCouponCode);
        if (couponCode !== '') {
            const cartTotal = yield select(selectCartTotal);
            const cartPrice = yield select(selectPrice);
            const url = "https://foodapi.railyatri.in/live/index.php/MapiV2/Four/coupon_code/" +
                couponCode + "/h_total/" + cartTotal + "/base_price/" + cartPrice.base_price;
            const res = yield call(fetch, url);
            const items = yield res.json();
            if (items.msg === 'Coupon applied successfully!') {
                yield put(setCouponApplySuccess(items));
                yield call(onSetPriceAsync);
            } else {
                yield put(setCouponApplySuccess(items));
            }
        }

    } catch (error) {
        yield put(setCouponApplyFailure(error));
    }
}

export function* onApplyCouponStart() {
    yield takeLatest(cartActionTypes.APPLY_COUPON_CODE_START, onApplyCouponAsync)
}
export function* onSetPriceAsync() {
    try {
        const station = yield select(selectStation);
        var resId = station.res_id;
        var resDetails = yield resId.split('_');
        const res_id = resDetails[0];
        const menu = yield select(selectGetItemXQty);
        const menuString = menu.join();
        if (menuString !== '') {
            var discount = 0;
            const coupondata = yield select(selectCouponData);
            if (coupondata && coupondata.discount !== undefined) {
                discount = yield coupondata.discount;
            }
            const onlineDiscount = 0;
            const ryCashPlus = 0;
            const ryCash = 0;
            const paymentType = 1;
            const PHPSESSID = yield Cookie.get('PHPSESSID');
            const version = yield select(selectVersion);
const urlData = yield select(selectUrlData);
            const queryString = require('query-string');
            const parsed = queryString.parse(urlData)

            var url = yield "https://foodapi.railyatri.in/live/index.php/MapiV2/TwoV2/res_id/" +
                res_id + "/menu_string/" +
                menuString + "/discount/" +
                discount + "/online_discount/" +
                onlineDiscount + "/ry_cash_plus/" +
                ryCashPlus + "/ry_cash/" +
                ryCash + "/payment_type/" +
                paymentType + "/sesid/" +
                PHPSESSID + "/src/5/utm/" +
                parsed.utm_source;

            if (version === "3") {
                url = yield "https://foodapi.railyatri.in/live/index.php/MapiV3/TwoV3/res_id/" +
                    res_id + "/menu_string/" +
                    menuString + "/discount/" +
                    discount + "/online_discount/" +
                    onlineDiscount + "/ry_cash_plus/" +
                    ryCashPlus + "/ry_cash/" +
                    ryCash + "/payment_type/" +
                    paymentType + "/sesid/" +
                    PHPSESSID + "/src/5/utm/" +
                    parsed.utm_source;
            }
            const res = yield call(fetch, url);
            const items = yield res.json();
            yield put(setPriceSuccess(items));
        } else {
            const item = yield ({
                adjusted: 0, applicable_charge: 0, base_price: 0, delivery_charge: 0, discount: 0,
                online_discount: 0, ry_cash: 0, ry_cash_plus: 0, taxes: 0, to_pay: 0, you_save: 0
            })
            yield put(setPriceSuccess(item));
        }
    } catch (error) {
        yield put(setPriceFailure(error));
    }
}

export function* onSetPrice() {
    yield takeLatest(cartActionTypes.FETCH_PRICE_START, onSetPriceAsync)
}

export function* onremoveCoupon() {

    yield debounce(300, cartActionTypes.SET_COUPON_CODE, onSetPriceAsync)
}

export function* onSetValue() {
    yield put(setStationsDetailSuccess([]));
    yield put(setStation([]));
}
export function* onSetTrainPNR() {
    yield takeLatest(CartActionTypes.SET_PNR, onSetValue);
}
export function* onSetTrainBoardingDate() {
    yield takeLatest(CartActionTypes.SET_BOARDING_DATE, onSetValue);
}
export function* onSetTrainBoardingStation() {
    yield takeLatest(CartActionTypes.SET_BOARDING_STATION, onSetValue);
}
export function* addMoreItemSet() {
    const station = yield select(selectStation);
    if (station && station.res_id !== undefined) {
        try {
            var resId = station.res_id;
            var resDetails = yield resId.split('_');
            const res_id = resDetails[0];
            const rest_name = resDetails[1];
            const food_type = yield station.Ftype;
            const qmid = yield select(selectQmid);
            const qmids = yield qmid.join();
            const PHPSESSID = yield Cookie.get('PHPSESSID');
            const version = yield select(selectVersion);
            const urlData = yield select(selectUrlData);
            const queryString = require('query-string');
            const parsed = queryString.parse(urlData)
            var url = 'https://foodapi.railyatri.in/live/index.php/MapiVeg/AddMoreItem/req_res_id/' + res_id + '/qmIds/' + qmids + '/food_type/' + food_type + '/rest_name/' + rest_name + '/sesid/' + PHPSESSID + '/src/5/utm/' + parsed.utm_source;
            var urlAll = 'https://foodapi.railyatri.in/live/index.php/MapiVeg/AddMoreItem/req_res_id/' + res_id + '/qmids/' + qmids + '/food_type/' + food_type + '/rest_name/' + rest_name + '/sesid/' + PHPSESSID + '/src/5/utm/' + parsed.utm_source;

            if (version === "3") {
                url = 'https://foodapi.railyatri.in/live/index.php/MapiVeg/AddMoreItem/req_res_id/' + res_id + '/qmIds/' + qmids + '/food_type/' + food_type + '/rest_name/' + rest_name + '/sesid/' + PHPSESSID + '/src/5/utm/' + parsed.utm_source;
                urlAll = 'https://foodapi.railyatri.in/live/index.php/MapiVeg/AddMoreItem/req_res_id/' + res_id + '/qmids/' + qmids + '/food_type/' + food_type + '/rest_name/' + rest_name + '/sesid/' + PHPSESSID + '/src/5/utm/' + parsed.utm_source;
            }
            const res = yield call(fetch, url);
            const items = yield res.json();
            yield put(setAddMoreItem(items));
            const page = yield select(selectPageName);
            const resp = yield call(fetch, urlAll);
            const itemsAll = yield resp.json();
            const cartItems = yield select(selectCartItems);
            const updatecart = yield updateCartItemStatus(cartItems, itemsAll.active_menus);
            yield put({ type: cartActionTypes.UPDATE_CART_STATUS, payload: updatecart })
            if (page === 'Review Page') {
                yield call(onSetPriceAsync);
            }

        } catch (error) {
            yield put(setAddMoreItemFailure(error))
        }
    }
}
export function* onStationSelectSet() {
    const station = yield select(selectStation);
    const version = yield select(selectVersion);

    if (station && station.active_menulist !== undefined) {
        const rest_id = yield station.res_id.split('_');
        var url = "https://foodapi.railyatri.in/live/index.php/mapiV2/Sampling/res_id/" + rest_id[0];
        if (version === "3") {
            yield call(onStationSelectV3);
            url = "https://foodapi.railyatri.in/live/index.php/mapiV3/Sampling/res_id/" + rest_id[0];
        }
        const cartItems = yield select(selectCartItems);
        const updatecart = yield updateCartItemStatus(cartItems, station.active_menulist);
        yield put({ type: cartActionTypes.UPDATE_CART_STATUS, payload: updatecart });
        const res = yield call(fetch, url);
        const samples = yield res.json();
        if (samples.status === 200) {
            yield put(setProductSample(samples.output));
            const sampleData = samples.output.map(item => (item.sample_id));
            yield put(setSampleSelected(sampleData))

        } else {
            yield put(setProductSample([]));
            yield put(setSampleSelected([]))
        }
    } else {
        yield put(setProductSample([]));
        yield put(setSampleSelected([]))
    }
    //yield call(addMoreItemSet);

}
export function* onStationSelect() {
    yield debounce(300, CartActionTypes.SET_SELECTED_STATION, onStationSelectSet)
}
export function* onGetTrainDataAllAsync() {
    yield put(setVersion('3'));
    const trainNo = yield select(selectTrainNo);
    const broadingStation = yield select(selectBoardingStation);
    const boardingDate = yield select(selectBoardingDate);
    var pnr = yield select(selectPnr);
    const PHPSESSID = yield Cookie.get('PHPSESSID');
    const tab = yield select(selectHomeTab)
const urlData = yield select(selectUrlData);
    const queryString = require('query-string');        
    const parsed = queryString.parse(urlData);
    if (pnr && pnr.length === 10 && tab === 'PNR') {
        const url = 'https://foodapi.railyatri.in/live/index.php/mapiVeg/getPnrDetails/pnr/' + pnr + '/type/web/sessID/' + PHPSESSID + '/src/5/utm/'+parsed.utm_source;
        const res = yield call(fetch, url);
        const data = yield res.json();
        if (data.status === 200) {
            yield put(setPnrData(data));
            yield put(setBoardingStation(data.boardingstation));
            yield put(setBoardingDate(data.boardingdate));
            yield put(setTrainNo(data.trainno));
            if (data.coach) {
                yield put(setCoachNumber(data.coach))
            }
            if (data.seat) {
                yield put(setSeatNumber(data.seat))
            }
        } else {
            yield put(setCoachNumber(''));
            yield put(setSeatNumber(''));
        }
    } else {
        pnr = '';
        yield put(setCoachNumber(''));
        yield put(setSeatNumber(''));
    }
    if ((trainNo !== '' && broadingStation !== '' && boardingDate !== '') || (pnr !== '' && boardingDate !== '')) {

        try {
            const url = 'https://foodapi.railyatri.in/live/index.php/mapiVeg/trainlist';
            const qmids = 'WithOutQmId';
            var formData = new FormData();

            if (pnr) {
                formData.append("qmid", qmids);
                formData.append("pnr", pnr);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
formData.append("utm", parsed.utm_source);
            } else {
                formData.append("train", trainNo);
                formData.append("boarding", broadingStation);
                formData.append("bdate", boardingDate);
                formData.append("qmid", qmids);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
formData.append("utm", parsed.utm_source);
            }

            const requestOptions = yield {
                method: 'POST',
                body: formData
            };
            const res = yield call(fetch, url, requestOptions);
            const stations = yield res.json();
            yield put(setStationsDetailAllSuccess(stations));
            yield put(setStationFetching(false));

            if (stations.msg === undefined) {
                const selectedStation = yield select(selectStation);
                const stationToggle = yield select(selectStationToggle);
                const page = yield select(selectPageName)

                if (selectedStation && selectedStation.name === undefined) {
                    yield put(setStation(stations[0]));
                } else {
                    const existStation = stations.find(station => station.name === selectedStation.name);
                    if (existStation === undefined) {
                        yield put(setStation(stations[0]));
                    }
                }

                yield call(onStationSelectV3);
                yield put(ToggleStationList());
                yield call(addMoreItemSet);

                if (page === 'Journey Details') {
                    yield window.location.href = '/all-station-details';
                }


            }

        } catch (error) {
            yield put(setStationsDetailAllFailure(error))
            yield put(setStationFetching(false));
        }
    }
}

export function* onGetTrainDataAllAsyncNew() {
    yield put(setVersion('3'));
    const trainNo = yield select(selectTrainNo);
    const broadingStation = yield select(selectBoardingStation);
    const boardingDate = yield select(selectBoardingDate);
    const pnr = yield select(selectPnr);
    const PHPSESSID = yield Cookie.get('PHPSESSID');

    if ((trainNo !== '' && broadingStation !== '' && boardingDate !== '') || (pnr !== '' && boardingDate !== '')) {

        try {
            const url = 'https://foodapi.railyatri.in/live/index.php/mapiVeg/trainlist';
            const qmids = 'WithOutQmId';
            var formData = new FormData();

            if (pnr) {
                formData.append("qmid", qmids);
                formData.append("pnr", pnr);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
            } else {
                formData.append("train", trainNo);
                formData.append("boarding", broadingStation);
                formData.append("bdate", boardingDate);
                formData.append("qmid", qmids);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
            }

            const requestOptions = yield {
                method: 'POST',
                body: formData
            };
            const res = yield call(fetch, url, requestOptions);
            const stations = yield res.json();
            yield put(setStationsDetailAllSuccess(stations));

            if (stations.msg === undefined) {
                const selectedStation = yield select(selectStation);
                const stationToggle = yield select(selectStationToggle);
                const page = yield select(selectPageName)

                if (selectedStation && selectedStation.name === undefined) {
                    yield put(setStation(stations[0]));
                } else {
                    const existStation = stations.find(station => station.name === selectedStation.name);
                    if (existStation === undefined) {
                        yield put(setStation(stations[0]));
                    }
                }

                yield call(onStationSelectV3);
                yield put(ToggleStationList());
                yield call(addMoreItemSet);

                if (page === 'Journey Details') {
                    const urlData = yield select(selectUrlData);
                    yield window.location.href = '/all-station-details';
                }


            } else {
                yield put(setStationFetching(false));
            }

        } catch (error) {
            yield put(setStationsDetailAllFailure(error))
        }
    }
}

export function* onGetTrainDataAll() {
    yield takeLatest(CartActionTypes.FETCH_ALL_STATIONS_START, onGetTrainDataAllAsync);
}
export function* onGetTrainDataAsync() {
    const trainNo = yield select(selectTrainNo);
    const broadingStation = yield select(selectBoardingStation);
    const boardingDate = yield select(selectBoardingDate);
    const PHPSESSID = yield Cookie.get('PHPSESSID');
    const pnr = yield select(selectPnr);
    if (pnr.length === 10) {
        const url = 'https://foodapi.railyatri.in/live/index.php/mapiV2/getPnrDetails/pnr/' + pnr + '/type/web/sessID/' + PHPSESSID + '/src/5';
        const res = yield call(fetch, url);
        const data = yield res.json();
        if (data.status === 200) {
            yield put(setPnrData(data));
            yield put(setBoardingStation(data.boardingstation));
            yield put(setBoardingDate(data.boardingdate));
            yield put(setTrainNo(data.trainno));
            if (data.coach) {
                yield put(setCoachNumber(data.coach))
            }
            if (data.seat) {
                yield put(setSeatNumber(data.seat))
            }
        }
    }
    if ((trainNo !== '' && broadingStation !== '' && boardingDate !== '') || (pnr !== '' && boardingDate !== '')) {

        try {
            const url = 'https://foodapi.railyatri.in/live/index.php/mapiV2/trainlist';
            const qmid = yield select(selectQmid)
            const qmids = qmid.join();

            var formData = new FormData();

            if (pnr) {
                formData.append("qmid", qmids);
                formData.append("pnr", pnr);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
            } else {
                formData.append("train", trainNo);
                formData.append("boarding", broadingStation);
                formData.append("bdate", boardingDate);
                formData.append("qmid", qmids);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
            }

            const requestOptions = yield {
                method: 'POST',
                body: formData
            };
            const res = yield call(fetch, url, requestOptions);
            const stations = yield res.json();
            yield put(setStationsDetailSuccess(stations));

            if (stations.msg === undefined) {
                const selectedStation = yield select(selectStation);
                const stationToggle = yield select(selectStationToggle);
                const page = yield select(selectPageName)

                if (selectedStation && selectedStation.name === undefined) {
                    yield put(setStation(stations[0]))
                } else {
                    const existStation = stations.find(station => station.name === selectedStation.name);
                    if (existStation === undefined) {
                        yield put(setStation(stations[0]))
                    }
                }

                if (stations.length === 1 && page === 'Station List' && stationToggle === true) {
                    yield put(ToggleStationList())
                }
                yield call(addMoreItemSet);


                if (page === 'Station Details') {
                    const urlData = yield select(selectUrlData);
                    yield window.location.href = '/station-details';
                }
            } else {
                yield put(setStationFetching(false));
            }

        } catch (error) {
            yield put(setStationsDetailFailure(error))
        }
    }
}

export function* onGetTrainDataAsyncNew() {
    const trainNo = yield select(selectTrainNo);
    const broadingStation = yield select(selectBoardingStation);
    const boardingDate = yield select(selectBoardingDate);
    const pnr = yield select(selectPnr);
    const PHPSESSID = yield Cookie.get('PHPSESSID');

    if ((trainNo !== '' && broadingStation !== '' && boardingDate !== '') || (pnr !== '' && boardingDate !== '')) {

        try {
            const url = 'https://foodapi.railyatri.in/live/index.php/mapiV2/trainlist';
            const qmid = yield select(selectQmid)
            const qmids = qmid.join();

            var formData = new FormData();

            if (pnr) {
                formData.append("qmid", qmids);
                formData.append("pnr", pnr);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
            } else {
                formData.append("train", trainNo);
                formData.append("boarding", broadingStation);
                formData.append("bdate", boardingDate);
                formData.append("qmid", qmids);
                formData.append("src", 5);
                formData.append("sesid", PHPSESSID);
            }

            const requestOptions = yield {
                method: 'POST',
                body: formData
            };
            const res = yield call(fetch, url, requestOptions);
            const stations = yield res.json();
            yield put(setStationsDetailSuccess(stations));

            if (stations.msg === undefined) {
                const selectedStation = yield select(selectStation);
                const stationToggle = yield select(selectStationToggle);
                const page = yield select(selectPageName)

                if (selectedStation && selectedStation.name === undefined) {
                    yield put(setStation(stations[0]))
                } else {
                    const existStation = stations.find(station => station.name === selectedStation.name);
                    if (existStation === undefined) {
                        yield put(setStation(stations[0]))
                    }
                }

                if (stations.length === 1 && page === 'Station List' && stationToggle === true) {
                    yield put(ToggleStationList())
                }
                yield call(addMoreItemSet);


                if (page === 'Station Details') {
                    const urlData = yield select(selectUrlData);
                    yield window.location.href = '/station-details';
                }
            } else {
                yield put(setStationFetching(false));
            }

        } catch (error) {
            yield put(setStationsDetailFailure(error))
        }
    }
}

export function* onGetTrainData() {
    yield takeLatest(CartActionTypes.FETCH_STATIONS_START, onGetTrainDataAsync);
}

export function* onDeleteHideCart() {

    try {
        const ItemTotal = yield select(selectCartItemsCount);
        const toggle = yield select(selectToggle);
        const page = yield select(selectPageName);
        const version = yield select(selectVersion);


        if (ItemTotal === 0 && toggle === true) {
            yield put({ type: CartActionTypes.HIDE_CART })
            yield put({ type: CartActionTypes.SET_PAGE, payload: 'Journey Details' })
        }
        if (ItemTotal === 0 && page === 'Journey Details') {
            yield put(setVersion(''));
        }
        if (ItemTotal === 0 && page === 'All Station List') {
            yield put(ToggleEmptyCartPop(''));
        }
        if (page !== 'Journey Details' && page !== 'Review Page') {
            if (version === "3") {
                yield call(addMoreItemSet);
                // yield call(onGetTrainDataAllAsyncNew);
            } else {
                yield call(onGetTrainDataAsyncNew);
            }
        }
        if (page === 'Review Page') {
            const coupondata = yield select(selectCouponData);
            if (coupondata && coupondata.discount !== undefined) {
                yield put(setCouponData([]));
            }
            yield call(addMoreItemSet);
            if (ItemTotal === 0) {
                const addmoreItemToggle = yield select(selectAddMoreItemToggle);
                if (addmoreItemToggle === false) {
                    yield put(toggleAddMoreItems());
                }
            }
        }

    } catch (error) {
        console.log('onDeleteHideCart error')
    }
}
export function* onAddItemToCart() {
    const page = yield select(selectPageName);
    const version = yield select(selectVersion);

    if (page !== 'Journey Details' && page !== 'Review Page') {

        if (version === "3") {
            yield call(addMoreItemSet);
            //yield call(onGetTrainDataAllAsyncNew);
        } else {
            yield call(onGetTrainDataAsyncNew);
        }
    }
    if (page === 'All Station List') {
        yield put(ToggleEmptyCartPop(''));
    }
    if (page === 'Review Page') {
        const coupondata = yield select(selectCouponData);
        if (coupondata && coupondata.discount !== undefined) {
            yield put(setCouponData([]));
        }
        yield call(addMoreItemSet);
    }
}
export function* onDelete() {
    yield takeLatest(CartActionTypes.REMOVE_ITEM, onDeleteHideCart);
}
export function* onAddCart() {
    yield takeLatest(CartActionTypes.ADD_ITEM, onAddItemToCart);
}

export function* cartSaga() {
    yield all([
        call(onDelete),
        call(onGetTrainData),
        call(onGetTrainDataAll),
        //call(onSetTrainPNR),
        call(onSetTrainBoardingDate),
        call(onSetTrainBoardingStation),
        call(onStationSelect),
        call(onAddCart),
        call(onSetPrice),
        call(onApplyCouponStart),
        call(onIncompleteStart),
        call(onCheckLeadTimeStart),
        call(onremoveCoupon),
        call(onfetchPnrData),
        call(onBulkOrder)
    ])
}