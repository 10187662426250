import {combineReducers} from 'redux';
import {persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import Cookie from 'js-cookie';

import MenuCategoryReducer from './menu-category/menu-category.reducer';
import MenuItemReducer from './menu-item/menu-item.reducer';
import MainBanner from './main-banner/main-banner.reducer';
import SmallBanner from './small-banner/small-banner.reducer';
import Cart from './cart/cart.reducer';

const persistConfig  = {
    key:'railyatri',
    storage,
    whiteList: ['cart','menuCategory','menuItem'],
    blacklist:[]
};

const appReducer = combineReducers({

    menuCategory:MenuCategoryReducer,
    menuItem: MenuItemReducer,
    mainBanner: MainBanner,
    smallBanner: SmallBanner,
    cart:Cart

});

const rootReducer = (state, action) => {
    if (action.type === "RESET") {
        storage.removeItem('persist:railyatri');
        Cookie.remove('PHPSESSID');
        localStorage.clear();
        state = undefined;
    }
    return appReducer(state, action);
};

export default persistReducer(persistConfig,rootReducer);