import SmallBannerTypes from './small-banner.types';

const INITIAL_STATE = {
    smallBanner: null,
    isFetching: false,
    errorMessage: undefined
}

const SmallBannerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SmallBannerTypes.FETCH_SMALLBANNER_START:            
            return {
                ...state,
                isFetching:true
            }
        case SmallBannerTypes.FETCH_SMALLBANNER_SUCCESS:
            return {
                ...state,
                isFetching: false,
                smallBanner:action.payload
            }
        case SmallBannerTypes.FETCH_SMALLBANNER_FAILURE:
            return {
                ...state,
                isFetching: false,
                errorMessage: action.payload
            }
        default:
            return state;

    }
}
export default SmallBannerReducer;