import SmallBannerTypes from './small-banner.types';

export const smallBannerSelectionStart = () =>({
    type: SmallBannerTypes.FETCH_SMALLBANNER_START,   
})
export const smallBannerSelectionSuccess = smallBanner =>({
    type:SmallBannerTypes.FETCH_SMALLBANNER_SUCCESS,
    payload:smallBanner
})

export const smallBannerSelectionFailure = errorMessage =>({
    type: SmallBannerTypes.FETCH_SMALLBANNER_FAILURE,
    payload:errorMessage
})