import { takeLatest, put, call, all,select } from 'redux-saga/effects';

import { menuCategorySelectionSuccess, menuCategorySelectionFailure } from './menu-category.action';
import { selectToggle } from '../cart/cart.selector'
import MenuCategoryTypes from './menu-category.types';
import CartActionTypes from '../cart/cart.types';

export function* fetchMenuCategoryAsync() {
    try {
        const cartHide = yield select(selectToggle);
        if(cartHide === true){
            yield put({ type: CartActionTypes.HIDE_CART})
        } 
          
        const res = yield call(fetch,'https://foodapi.railyatri.in/live/mapiV2/qmcategory')
        const menuCategory = yield res.json();
        yield put(menuCategorySelectionSuccess(menuCategory))        

    } catch (error) {
        yield put(menuCategorySelectionFailure(error));
    }
}

export function* fetchMenuCategoryStart() {
    yield takeLatest(MenuCategoryTypes.FETCH_MENUCATEGORY_START, fetchMenuCategoryAsync)
}

export function* menuCategorySaga() {
    yield all([
        call(fetchMenuCategoryStart)
    ])
}