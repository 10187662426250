import MenuCategoryTypes from './menu-category.types';

export const menuCategorySelectionStart = () =>({
    type: MenuCategoryTypes.FETCH_MENUCATEGORY_START,   
})
export const menuCategorySelectionSuccess = menuCategoryMap =>({
    type:MenuCategoryTypes.FETCH_MENUCATEGORY_SUCCESS,
    payload:menuCategoryMap
})

export const menuCategorySelectionFailure = errorMessage =>({
    type: MenuCategoryTypes.FETCH_MENUCATEGORY_FAILURE,
    payload:errorMessage
})