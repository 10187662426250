import { takeLatest,call,put,all,select} from 'redux-saga/effects'
import Cookie from 'js-cookie';

import { selectUrlData } from "./../cart/cart.selector"
import { fetchMenuItemSuccess,fetchMenuItemFailure } from './menuItem.action';
import menuItemTypes from './menu-item.types';

export function* fetchMenuItemAsync(){
    try {
        const PHPSESSID = yield Cookie.get('PHPSESSID');
        const  urlData = yield select(selectUrlData);
        const queryString = require('query-string');        
        const parsed = queryString.parse(urlData);
        const res = yield call(fetch,'https://foodapi.railyatri.in/live/index.php/mapiV2/qmlistv2/type/0/sessID/'+PHPSESSID+'/src/1/utm/'+parsed.utm_source);
        const menuItem = yield res.json();
        yield put(fetchMenuItemSuccess(menuItem));
    } catch (error){
        yield put(fetchMenuItemFailure(error));
    }
}

export function* fetchMenuItemStart(){
    yield takeLatest(menuItemTypes.FETCH_MENUITEM_START,fetchMenuItemAsync)
}

export function* menuItemSaga() {
    yield all([
        call(fetchMenuItemStart)
    ])
}