import { takeLatest, put, call, all } from 'redux-saga/effects';

import { mainBannerSelectionSuccess, mainBannerSelectionFailure } from './main-banner.action';
import mainBannerTypes from './main-banner.types';

export function* fetchMainBannerAsync() {
    try {
        const res = yield call(fetch, 'https://foodapi.railyatri.in/live/mapiV2/BannerData/src/1')
        const mainBanner = yield res.json();
        if(mainBanner.success !== false) {
            yield put(mainBannerSelectionSuccess(mainBanner));
        } else {
            yield put(mainBannerSelectionSuccess(null));
        }

    } catch (error) {
        yield put(mainBannerSelectionFailure(error));
    }
}

export function* fetchMainBannerStart() {
    yield takeLatest(mainBannerTypes.FETCH_MAINBANNER_START, fetchMainBannerAsync)
}

export function* mainBannerSaga() {
    yield all([
        call(fetchMainBannerStart),
    ])
}