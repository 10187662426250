import menuItemTypes from './menu-item.types';

const INITIAL_STATE = {
    menuItem: null,
    isMenuLoading: false,
    errorMessage: undefined
}

const menuItemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case menuItemTypes.FETCH_MENUITEM_START:
            return {
                ...state,
                isMenuLoading: true
            }
        case menuItemTypes.FETCH_MENUITEM_SUCCESS:
            return {
                ...state,
                isMenuLoading: false,
                menuItem: action.payload
            }
        case menuItemTypes.FETCH_MENUITEM_FAILURE:
            return {
                ...state,
                isMenuLoading: false,
                errorMessage: action.payload
            }
        default:
            return state;
    }
}

export default menuItemReducer;