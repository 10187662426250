import CartActionTypes from './cart.types';
import { addItemToCart, removeItemFromCart, addOnCheck, addSample } from './cart.utills';
import cartActionTypes from './cart.types';

const INITIAL_STATE = {
    version: '',
    emptyCartPop: '',
    exitPopupHidden: true,
    homeTab: 'TRAIN',
    cartItems: [],
    isFetching: false,
    cartHidden: false,
    errorMessage: undefined,
    addOnCheck: false,
    pageName: 'Journey Details',
    trainNo: '',
    boardingStation: '',
    boardingDate: '',
    expireDate: '',
    pnr: '',
    stations: [],
    isStationFetching: false,
    stationErrorMessage: undefined,
    selectedStation: [],
    addMoreItems: [],
    stationListHide: false,
    addMoreItemHide: false,
    price: [],
    isPriceFetching: false,
    priceErrorMessage: undefined,
    couponCode: '',
    couponData: [],
    isCouponFetching: false,
    couponError: undefined,
    name: '',
    phoneNumber: '',
    seatNumber: '',
    coachNumber: '',
    email: '',
    comments: '',
    leadTimeData: [],
    isLeadTimeFetching: false,
    leadTimeError: undefined,
    inCompleteData: [],
    isInCompleteFetching: false,
    inCompleteError: undefined,
    zeroContact: false,
    sampleData: [],
    sampleSelected: [],
    pnrData: [],
    pnrErrorMessage: undefined,
    bulkOrder: [],
    isBulkOrderLoading: false,
    bulkOrderErrorMessge: undefined,
    urlData: ''
}

const cartReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CartActionTypes.ADD_ITEM:
            return {
                ...state,
                cartItems: addItemToCart(state.cartItems, action.payload)
            }
        case CartActionTypes.UPDATE_CART_STATUS:
            return {
                ...state,
                cartItems: action.payload
            }
        case cartActionTypes.REMOVE_ITEM:
            return {
                ...state,
                cartItems: removeItemFromCart(state.cartItems, action.payload)
            }
        case cartActionTypes.REMOVE_ALL_ITEM:
            return {
                ...state,
                cartItems: []
            }
        case cartActionTypes.HIDE_CART:
            return {
                ...state,
                cartHidden: !state.cartHidden
            }
        case cartActionTypes.EMPTY_CART_POP:
            return {
                ...state,
                emptyCartPop: action.payload
            }
        case cartActionTypes.EXIT_POP_UP:
            return {
                ...state,
                exitPopupHidden: !state.exitPopupHidden
            }
        case cartActionTypes.ADD_ON_CHECK:
            return {
                ...state,
                addOnCheck: addOnCheck(state.cartItems, state.pageName)
            }
        case cartActionTypes.SET_PAGE:
            return {
                ...state,
                pageName: action.payload
            }
case cartActionTypes.SET_URL_DATA:
            return {
                ...state,
                urlData: action.payload
            }
        case cartActionTypes.SET_STATIONS:
            return {
                ...state,
                stations: action.payload
            }
        case cartActionTypes.SET_TRAIN_NO:
            return {
                ...state,
                trainNo: action.payload
            }
        case cartActionTypes.SET_BOARDING_STATION:
            return {
                ...state,
                boardingStation: action.payload
            }
        case cartActionTypes.SET_BOARDING_DATE:
            return {
                ...state,
                boardingDate: action.payload
            }
        case cartActionTypes.SET_EXPIRE_DATE:
            return {
                ...state,
                expireDate: action.payload
            }
        case cartActionTypes.SET_PNR:
            return {
                ...state,
                pnr: action.payload
            }
        case cartActionTypes.FETCH_STATIONS_START:
            return {
                ...state,
                isStationFetching: true
            }
        case cartActionTypes.FETCH_STATIONS_SUCCESS:
            return {
                ...state,
                stations: action.payload
            }
        case cartActionTypes.FETCH_STATIONS_FAILURE:
            return {
                ...state,
                isStationFetching: false,
                stationErrorMessage: action.payload
            }
        case cartActionTypes.BULK_ORDER_START:
            return {
                ...state,
                isBulkOrderLoading: true
            }
        case cartActionTypes.BULK_ORDER_SUCCESS:
            return {
                ...state,
                bulkOrder: action.payload
            }
        case cartActionTypes.BULK_ORDER_FAILURE:
            return {
                ...state,
                isBulkOrderLoading: false,
                bulkOrderErrorMessge: action.payload
            }
        case cartActionTypes.FETCH_PNR_START:
            return {
                ...state,
            }
        case cartActionTypes.FETCH_PNR_SUCCESS:
            return {
                ...state,
                pnrData: action.payload
            }
        case cartActionTypes.FETCH_STATIONS_FAILURE:
            return {
                ...state,
                pnrErrorMessage: action.payload
            }
        case cartActionTypes.FETCH_ALL_STATIONS_START:
            return {
                ...state,
                isStationFetching: true
            }
        case cartActionTypes.FETCH_ALL_STATIONS_SUCCESS:
            return {
                ...state,
                stations: action.payload
            }
        case cartActionTypes.FETCH_ALL_STATIONS_FAILURE:
            return {
                ...state,
                isStationFetching: false,
                stationErrorMessage: action.payload
            }
        case cartActionTypes.SET_SELECTED_STATION:
            return {
                ...state,
                selectedStation: action.payload
            }
        case cartActionTypes.SET_ADD_MORE_ITEM:
            return {
                ...state,
                addMoreItems: action.payload
            }
        case cartActionTypes.HIDE_STATION_LIST:
            return {
                ...state,
                stationListHide: !state.stationListHide
            }
        case cartActionTypes.HIDE_ADD_MORE_ITEMS:
            return {
                ...state,
                addMoreItemHide: !state.addMoreItemHide
            }
        case cartActionTypes.FETCH_PRICE_START:
            return {
                ...state,
                isPriceFetching: true
            }
        case cartActionTypes.FETCH_PRICE_SUCCESS:
            return {
                ...state,
                isPriceFetching: false,
                price: action.payload
            }
        case cartActionTypes.FETCH_PRICE_FAILURE:
            return {
                ...state,
                isPriceFetching: false,
                PriceErrorMessage: action.payload
            }
        case cartActionTypes.APPLY_COUPON_CODE_START:
            return {
                ...state,
                isCouponFetching: true
            }
        case cartActionTypes.APPLY_COUPON_CODE_SUCCESS:
            return {
                ...state,
                isCouponFetching: false,
                couponData: action.payload
            }
        case cartActionTypes.APPLY_COUPON_CODE_FAILURE:
            return {
                ...state,
                isCouponFetching: false,
                couponError: action.payload
            }
        case cartActionTypes.SET_COUPON_CODE:
            return {
                ...state,
                couponCode: action.payload
            }
        case cartActionTypes.SET_COUPON_DATA:
            return {
                ...state,
                couponData: action.payload
            }
        case cartActionTypes.SET_PHONE_NO:
            return {
                ...state,
                phoneNumber: action.payload
            }
        case cartActionTypes.SET_NAME:
            return {
                ...state,
                name: action.payload
            }
        case cartActionTypes.SET_SEAT_NUMBER:
            return {
                ...state,
                seatNumber: action.payload
            }
        case cartActionTypes.SET_COACH_NUMBER:
            return {
                ...state,
                coachNumber: action.payload
            }
        case cartActionTypes.SET_EMAIL_ID:
            return {
                ...state,
                email: action.payload
            }
        case cartActionTypes.SET_COMMENT:
            return {
                ...state,
                comments: action.payload
            }
        case cartActionTypes.SET_ZERO_CONTACT:
            return {
                ...state,
                zeroContact: action.payload
            }
        case cartActionTypes.LEAD_TIME_CHECK_START:
            return {
                ...state,
                isLeadTimeFetching: true,
            }
        case cartActionTypes.LEAD_TIME_CHECK_SUCCESS:
            return {
                ...state,
                isLeadTimeFetching: false,
                leadTimeData: action.payload
            }
        case cartActionTypes.LEAD_TIME_CHECK_FAILURE:
            return {
                ...state,
                isLeadTimeFetching: false,
                leadTimeError: action.payload
            }
        case cartActionTypes.INCOMPLETE_ORDER_START:
            return {
                ...state,
            }
        case cartActionTypes.SET_INCOMPLETE_IS_FETCHING:
            return {
                ...state,
                isInCompleteFetching: action.payload
            }
        case cartActionTypes.SET_STATION_IS_FETCHING:
            return {
                ...state,
                isStationFetching: action.payload
            }
        case cartActionTypes.INCOMPLETE_ORDER_SUCCESS:
            return {
                ...state,
                isInCompleteFetching: false,
                inCompleteData: action.payload
            }
        case cartActionTypes.INCOMPLETE_ORDER_FAILURE:
            return {
                ...state,
                isInCompleteFetching: false,
                incompleteError: action.payload
            }
        case cartActionTypes.SET_PRODUCT_SAMPLES:
            return {
                ...state,
                sampleData: action.payload
            }
        case cartActionTypes.SET_SAMPLE_SELECTED:
            return {
                ...state,
                sampleSelected: addSample(state.sampleSelected, action.payload)
            }
        case cartActionTypes.SET_VERSION:
            return {
                ...state,
                version: action.payload
            }
        case cartActionTypes.SET_HOME_TAB:
            return {
                ...state,
                homeTab: action.payload
            }
        default:
            return state;
    }
}

export default cartReducer;