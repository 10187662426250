import { createSelector } from 'reselect';
import { cartCount, cartTotal } from './cart.utills'

const selectCart = state => state.cart;

export const selectCartItems = createSelector(
    [selectCart],
    (cart) => cart.cartItems
)
export const selectPageName = createSelector(
    [selectCart],
    (cart) => cart.pageName
)
export const selectCartItemsCount = createSelector(
    selectCartItems,
    selectPageName,
    (selectCartItems, selectPageName) => cartCount(selectCartItems, selectPageName)
)
export const selectCartTotal = createSelector(
    selectCartItems,
    selectPageName,
    (selectCartItems, selectPageName) => cartTotal(selectCartItems, selectPageName)
)
export const selectCartItem = id => createSelector(
    [selectCartItems],
    (item) => item ? item.find(item => item.id === id) : null,
)
export const selectToggle = createSelector(
    [selectCart],
    (cart) => cart.cartHidden
)
export const selectAddOnCheck = createSelector(
    [selectCart],
    (cart) => cart.addOnCheck
)
export const selectQmid = createSelector(
    [selectCartItems],
    (cartItems) => cartItems.map(item => (item.id + '')),
)
export const selectTrainNo = createSelector(
    [selectCart],
    (cart) => cart.trainNo
)
export const selectBoardingStation = createSelector(
    [selectCart],
    (cart) => cart.boardingStation
)
export const selectBoardingDate = createSelector(
    [selectCart],
    (cart) => cart.boardingDate
)
export const selectPnr = createSelector(
    [selectCart],
    (cart) => cart.pnr
)
export const selectStations = createSelector(
    [selectCart],
    (cart) => cart.stations
)
export const selectStationsCount = createSelector(
    [selectCart],
    (cart) => cart.stations.length
)
export const selectStation = createSelector(
    [selectCart],
    (cart) => cart.selectedStation
)
export const selectAddMoreItem = createSelector(
    [selectCart],
    (cart) => cart.addMoreItems
)
export const selectStationToggle = createSelector(
    [selectCart],
    (cart) => cart.stationListHide
)
export const selectAddMoreItemToggle = createSelector(
    [selectCart],
    (cart) => cart.addMoreItemHide
)
export const selectGetItemXQty = createSelector(
    [selectCartItems],
    (item) => item ? item.filter(item => item.status === 1)
        .map(item => 'qm' + item.id + '*' + item.quantity) : [],
)
export const selectPrice = createSelector(
    [selectCart],
    (cart) => cart.price
)
export const selectCouponCode = createSelector(
    [selectCart],
    (cart) => cart.couponCode
)
export const selectCouponData = createSelector(
    [selectCart],
    (cart) => cart.couponData
)
export const selectPhoneNo = createSelector(
    [selectCart],
    (cart) => cart.phoneNumber
)
export const selectName = createSelector(
    [selectCart],
    (cart) => cart.name
)
export const selectSeatNumber = createSelector(
    [selectCart],
    (cart) => cart.seatNumber
)
export const selectCoachNumber = createSelector(
    [selectCart],
    (cart) => cart.coachNumber
)
export const selectEmail = createSelector(
    [selectCart],
    (cart) => cart.email
)
export const selectComments = createSelector(
    [selectCart],
    (cart) => cart.comments
)
export const selectleadTime = createSelector(
    [selectCart],
    (cart) => cart.leadTimeData
)
export const selectcartData = createSelector(
    [selectCartItems],
    (cart) => cart.map(item => ({ id: item.id, item_name: item.item_name, price: item.price, qty: item.quantity, status: item.status }))
)
export const selectPassengerData = createSelector(
    [selectCart],
    (passData) => passData.inCompleteData
)
export const selectZeroContact = createSelector(
    [selectCart],
    (cart) => cart.zeroContact
)
export const selectInCompleteFetching = createSelector(
    [selectCart],
    (cart) => cart.isInCompleteFetching
)
export const selectStationFetching = createSelector(
    [selectCart],
    (cart) => cart.isStationFetching
)
export const selectProductSample = createSelector(
    [selectCart],
    (cart) => cart.sampleData
)
export const selectSamples = createSelector(
    [selectCart],
    (cart) => cart.sampleSelected
)
export const selectVersion = createSelector(
    [selectCart],
    (cart) => cart.version
)
export const selectHomeTab = createSelector(
    [selectCart],
    (cart) => cart.homeTab
)
export const selectExpireDate = createSelector(
    [selectCart],
    (cart) => cart.expireDate
)
export const selectEmptyCartPop = createSelector(
    [selectCart],
    (cart) => cart.emptyCartPop
)
export const selectExitPopUp = createSelector(
    [selectCart],
    (cart) => cart.exitPopupHidden
)
export const selectUrlData = createSelector(
    [selectCart],
    (cart) => cart.urlData
)