import React from "react";
import Skeleton from "react-loading-skeleton";

import './shimmer.style.css';
const Shimmer = () => {
    return (
        <div>
            <div className="header">
                <Skeleton height={130} width={"100%"} />
            </div>

            <ul className="list">
                {Array(6)
                    .fill()
                    .map((item, index) => (
                        <li className="card" key={index}>
                            <div className="card-title">
                                <div className="image-box">
                                    <Skeleton height={70} width={70} />
                                </div>
                                <div className="data-box">
                                    <Skeleton width={`77%`} />
                                    <Skeleton count={2} height={10}  width={`77%`}/>
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};
export default Shimmer;