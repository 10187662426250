import {all,call} from 'redux-saga/effects';

import {menuCategorySaga} from './menu-category/menu-category.saga';
import {menuItemSaga} from './menu-item/menu-item.saga';
import {mainBannerSaga} from './main-banner/main-banner.saga'
import {smallBannerSaga} from './small-banner/small-banner.saga';
import {cartSaga} from './cart/cart.saga'

export default function* rootSaga(){
    yield all([
        call(menuCategorySaga),
        call(menuItemSaga),
        call(mainBannerSaga),
        call(smallBannerSaga),
        call(cartSaga)
    ]);
}