import React, { Component, lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import Cookie from 'js-cookie';

import './App.css';
import { selectExpireDate } from './redux/cart/cart.selector';
import { createStructuredSelector } from 'reselect';

import { setExpireDate } from './redux/cart/cart.action'
import ErrorBoundary from './components/error-boundery/error-boundery.component';
import Shimmer from './components/shimmer/shimmer.component';


const HomePage = lazy(() =>
  import('./pages/homepage/homepage.component')
)
const StationDetails = lazy(() =>
  import('./pages/stationsdetails/stationdetails.component')
)
const AllStationDetails = lazy(() =>
  import('./pages/allstationsdetails/allstationdetails.component')
)
const ReviewPage = lazy(() =>
  import('./pages/reviewpage/reviewpage.component')
)
const OrderSummary = lazy(() =>
  import('./pages/order-summary/order-summary.component')
)
const Payment = lazy(() =>
  import('./pages/payment/payment.component')
)


class App extends Component {

  formatdate = (date) => {
    let dd = date.getDate();
    let mm = date.getMonth() + 1;
    let yyyy = date.getFullYear();

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return dd + "-" + mm + "-" + yyyy;
  }
  Reset = () => {
    this.props.reset();
    Cookie.remove('ST');
    window.location.href = "https://www.vegtrain.in/hi/";
  }
  componentWillMount() {
    const PHPSESSID = Cookie.get('PHPSESSID');

    if (PHPSESSID === undefined) {
      this.props.reset();
      const min = 1;
      const max = 10000000;
      const rand = min + Math.random() * (max - min);
      Cookie.set('PHPSESSID', rand, { expires: 2, path: '/', sameSite: "Strict" });
      this.props.setExpireDate(this.formatdate(new Date()));
      Cookie.remove('ST');
    } else {

      if (this.props.expireDate === '' || this.props.expireDate === undefined) {
        this.props.setExpireDate(this.formatdate(new Date()));
      }
      var bdate = this.props.expireDate.split('-');
      var date1 = new Date(bdate[2], bdate[1] - 1, bdate[0]);
      var date2 = new Date();

      var Difference_In_Time = date2.getTime() - date1.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
      if (Difference_In_Days > 1) {
        this.props.reset();
        Cookie.remove('ST');
      }
    }
  }

  render() {
    return (
      <div>
        <Switch>
          <ErrorBoundary>
            <Suspense fallback={<Shimmer />}>
              <Route exact path="/hi" component={this.Reset} />
              <Route exact path="/" component={HomePage} />
              <Route exact path="/:N([0-9]+.*)" component={HomePage} />
              <Route exact path="/food-:N([0-9a-z]+.*)" component={HomePage} />
              <Route exact path="/station-details" component={StationDetails} />
              <Route exact path="/all-station-details" component={AllStationDetails} />
              <Route exact path="/review-page" component={ReviewPage} />
              <Route exact path="/order-summary/id/:Id" component={OrderSummary} />
              <Route exact path="/order-summary/cid/:Cid" component={OrderSummary} />
              <Route exact path="/pay" component={Payment} />
            </Suspense>
          </ErrorBoundary>
        </Switch>
      </div >
    );
  }
}
const mapStateToProps = createStructuredSelector({
  expireDate: selectExpireDate,
})
const mapDispatchToProps = dispatch => ({
  reset: () => dispatch({ type: 'RESET' }),
  setExpireDate: (date) => dispatch(setExpireDate(date))
})

export default connect(mapStateToProps, mapDispatchToProps)(App);
