import MainBannerTypes from './main-banner.types';

const INITIAL_STATE = {
    mainBanner: null,
    isMainBannerFetching: false,
    errorMessage: undefined
}

const MainBannerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MainBannerTypes.FETCH_MAINBANNER_START:            
            return {
                ...state,
                isMainBannerFetching:true
            }
        case MainBannerTypes.FETCH_MAINBANNER_SUCCESS:
            return {
                ...state,
                isMainBannerFetching: false,
                mainBanner:action.payload
            }
        case MainBannerTypes.FETCH_MAINBANNER_FAILURE:
            return {
                ...state,
                isMainBannerFetching: false,
                errorMessage: action.payload
            }
        default:
            return state;

    }
}
export default MainBannerReducer;