export const addItemToCart = (cartItems, itemToAdd) => {

    if (cartItems && cartItems.length > 0) {
        const cartItemExists = cartItems.find(
            item => item.id === itemToAdd.id
        );

        if (cartItemExists) {
            return cartItems.map(item =>
                item.id === itemToAdd.id ?
                    { ...item, quantity: item.quantity + 1 } : item)
        }

        return [...cartItems, { ...itemToAdd, quantity: 1 }]
    }

    return [{ ...itemToAdd, quantity: 1 }]

}

export const removeItemFromCart = (cartItems, itemToRemove) => {
    const cartItemExists = cartItems.find(item =>
        item.id === itemToRemove.id
    );

    if (cartItemExists.quantity === 1) {
        return cartItems.filter(item => item.id !== itemToRemove.id)
    }

    return cartItems.map(item => (
        item.id === itemToRemove.id ?
            { ...item, quantity: item.quantity - 1 } : item
    ))
}

export const addOnCheck = (cartItems, page) => {

    if (cartItems && cartItems.length > 0) {

        var addOnItem = 0
        var cartlength = 0;
        if (page === 'Journey Details' || page === 'Station Details') {
            addOnItem = cartItems.filter(item => (item.addon === '1'));
            cartlength = cartItems.length;
        } else {
            addOnItem = cartItems.filter(item => ((item.addon === '1') && item.status === 1));
            const activeItemsCount = cartItems.filter(item => (item.status === 1));
            cartlength = activeItemsCount.length;
        }

        if (addOnItem.length === cartlength) {
            return true;
        }
        return false;
    }
}

export const updateCartItemStatus = (cartItems, activeItem) => {
    if (cartItems && cartItems.length > 0 && activeItem && activeItem.length > 0) {
        var items = [];

        activeItem.forEach(actItem => {
            cartItems.forEach((item, index) => {

                if (actItem.quick_menu_id === undefined) {
                    if (item.id === actItem.id) {
                        items[index] = { ...item, status: 1 }
                    }
                } else {
                    if (item.id === actItem.quick_menu_id) {
                        items[index] = { ...item, status: 1 }
                    }
                }
            })
        });

        cartItems.forEach((cartItem, index) => {
            if (items[index] === undefined) {
                items[index] = { ...cartItem, status: 0 }
            }
        });

        return items;
    }
}

export const cartCount = (cartItems, page) => {

    if (cartItems && cartItems.length > 0) {

        if (page === 'Journey Details' || page === 'Station Details') {
            return cartItems.length;
        } else {
            const cart = cartItems.filter(item => (item.status === 1));
            return cart.length;
        }

    }
    return 0;
}

export const cartTotal = (cartItems, page) => {

    if (cartItems && cartItems.length > 0) {

        if (page === 'Journey Details' || page === 'Station Details') {
            return cartItems.reduce((accumalatedPrice, cartItem) => accumalatedPrice + (parseInt(cartItem.price) * cartItem.quantity), 0);
        } else {
            const cart = cartItems.filter(item => (item.status === 1));

            return cart.reduce((accumalatedPrice, cartItem) => accumalatedPrice + (parseInt(cartItem.price) * cartItem.quantity), 0);
        }

    }
    return 0;
}
export const addSample = (samples, itemToAdd) => {

    if (samples && samples.length > 0) {
        if (itemToAdd.length === 0) {
            return [];
        }
        const sampleExists = samples.find(item =>
            item === itemToAdd
        );
        
        if (sampleExists !== undefined) {
            return samples.filter(item => item !== itemToAdd)
        }
        return [ ...samples, itemToAdd ];
    } else {
        return [...itemToAdd]
    }

}