import menuItemTypes from './menu-item.types';

export const fetchMenuItemStart = () => ({
    type: menuItemTypes.FETCH_MENUITEM_START
})

export const fetchMenuItemSuccess = menuItem => ({
    type: menuItemTypes.FETCH_MENUITEM_SUCCESS,
    payload: menuItem
})

export const fetchMenuItemFailure = errorMessage =>({
    type: menuItemTypes.FETCH_MENUITEM_FAILURE,
    payload: errorMessage
})