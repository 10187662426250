import MenuCategoryTypes from './menu-category.types';

const INITIAL_STATE = {
    menuCategory: null,
    isfetching: false,
    errorMessage: undefined
}

const MenuCategoryReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MenuCategoryTypes.FETCH_MENUCATEGORY_START:
            return {
                ...state,
                isfetching:true
            }
        case MenuCategoryTypes.FETCH_MENUCATEGORY_SUCCESS:
            return {
                ...state,
                isfetching: false,
                menuCategory:action.payload
            }
        case MenuCategoryTypes.FETCH_MENUCATEGORY_FAILURE:
            return {
                ...state,
                isfetching: false,
                errorMessage: action.payload
            }
        default:
            return state;

    }
}
export default MenuCategoryReducer;