import React from 'react';
import { connect } from 'react-redux';

class errorBoundary extends React.Component {

    constructor() {
        super();
        this.state = {
            hasError: false
        };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.   
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {        
        console.log(error);
        this.props.reset();
    }

    render() {

        if (this.state.hasError) {
            return <div>Some Thing Went Wrong</div>
        }

        return this.props.children
    }
}
const mapDispatchToProps = dispatch => ({
    reset: () => dispatch({type:'RESET'})
})

export default connect(null,mapDispatchToProps)(errorBoundary);